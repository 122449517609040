var site = site || {};

site.account = site.account || {};
site.signin = site.signin || {};
site.userInfoCookie = site.userInfoCookie || {};
var validate_password_enabled = Drupal.settings.validate_password_enabled || 0;

site.userInfoCookie.getValue = site.userInfoCookie.getValue || function () {
  return '';
};

(function ($) {
  Drupal.behaviors.signIn = {
    attach: function (context, settings) {
      var signedIn = site.userInfoCookie.getValue('signed_in');

      if (signedIn == '0') {
        signedIn = false;
      }

      // Determine if user has signed in before by looking at the persistent
      // user cookie.
      var persistentCookie = Drupal.ELB.getJSONCookie('persistent_user_cookie');
      // @TODO: get email address too so we can put it in the input field
      var firstName = persistentCookie.first_name;
      var userDetected = !!firstName;
      var $overlayTemplate = $('#signin-overlay-template', context);
      var overlayContent = $overlayTemplate.html();
      var $triggerButton = $('.page-utilities__account-button, .js-launch-account, .field-mobile-menu .sign-in---my-account', context);
      // Determine the state of the overlay to show:
      var signInOverlayState = $.cookie('signInOverlayState', { path: '/' });

      // Delete the cookie immediately (apparently these are both needed?):
      $.cookie('signInOverlayState', null, { path: '/' });
      $.cookie('signInOverlayState', null);

      var isMobile = !$('body').hasClass('device-pc');
      var $signInPage = $('.sign-in-page', context);
      var isSignInPage = $signInPage.length;
      var colorboxSettings = {
        html: overlayContent,
        className: 'signin-overlay-wrapper',
        width: '100%',
        maxWidth: '1022px',
        fixed: true
      };

      if (isMobile) {
        colorboxSettings.top = '0px';
        colorboxSettings.height = '1000px';
      }
      function _launchOverlay(forceReturn, returnURL) {
        $.colorbox(colorboxSettings);

        var $overlay = $('.signin-overlay-wrapper .sign-in-component');

        // Redirect back to the current page
        // var returnURL = '?RETURN_URL=' + window.location.pathname;
        // Generally only registration sends you back to your last page, but
        // there are some cases where sign in can (i.e., "Save to Profile" in the
        // Foundation Finder)
        if (forceReturn) {
          $('form', $overlay).each(function () {
            if (!$('input[name=RETURN_URL]', this).length) {
              $(this).append('<input type="hidden" name="RETURN_URL" value="" />');
            }
          });
        }
        returnURL = returnURL || window.location.pathname + window.location.search;

        $('input[name=RETURN_URL]', $overlay).val(returnURL);

        $('.sign-in-component__form--registration input[name=RETURN_URL]', $overlay).val('/account/index.tmpl');

        if (validate_password_enabled) {
          site.account.initPasswordValidationTooltip();
        }

        _initForm($overlay);

        // Init selectboxes for desktop:
        if (!isMobile) {
          $('.selectbox', $overlay).selectBox();
          // Apply global js text input behavior:
          Drupal.behaviors.formTextInputs.attach($('.signin-overlay-wrapper'));
        }
      }

      function _initForm($wrapper) {
        var $registerForm = $('.sign-in-component__form--registration', $wrapper);
        var $registerConfirmForm = $('.sign-in-component__confirm--registration', $wrapper);
        var $signInForm = $('.sign-in-component__form--sign-in', $wrapper);
        var $showPass = $('input[name=SHOW_PASSWORD]', $wrapper);
        var $pass = $('input[type=password]', $registerForm);
        var $error_messages_list = $('ul.error_messages').find('li');
        var $visiblePass = '';

        $('.sign-in-component', $signInPage).show();

        // Set the appropriate class on the outer container to tell css what to
        // display. By default we show the registration
        // form, but if the overlay state cookie indicates we just registered or
        // signed in, we show the relevant confirmation screen instead. Finally,
        // if the user's ever logged in on this machine we display the sign in
        // form by default.

        // First off, if there's an error in the form, and we're trying to show
        // a confirmation page, go back a step:
        if (isSignInPage && $('input.error, select.error', $wrapper).length) {
          if (signInOverlayState == 'register-confirm') {
            signInOverlayState = 'register';
          } else if (signInOverlayState == 'signin-confirm') {
            signInOverlayState = 'signin';
          }
        }

        if (isSignInPage && $('#account_lockout\\.\\.').is(':visible')) {
          if (signInOverlayState == 'signin-confirm') {
            signInOverlayState = 'signin';
          }
        }

        // Toggle the class:
        if (signInOverlayState == 'register') {
          $wrapper.addClass('registration');
        } else if (signInOverlayState == 'register-confirm') {
          // $wrapper.addClass('registration-confirmation');
        } else if (signInOverlayState == 'signin-confirm') {
          // $wrapper.addClass('sign-in-confirmation');
        } else if (signInOverlayState == 'signin' || userDetected) {
          $wrapper.addClass('sign-in');
        }

        // Remove any stray error classes that may have ended up on the hidden forms:
        $('form:hidden', $wrapper).find('input.error, select.error').removeClass('error');

        // Preprocess the form:

        $pass.each(function () {
          $(this).after(
            '<div class="visible-pass-wrapper"><input class="visible-pass form-text" type="text" style="display: none;" /></div>'
          );
          if (!isMobile) {
            Drupal.behaviors.formTextInputs.attach($('.visible-pass-wrapper'));
          }
        });

        $visiblePass = $('.visible-pass', $wrapper);

        // Add the user's first name to the sign in confirmation screen header:
        if (firstName) {
          var $signInConfirmHeader = $('.sign-in-component__confirm--sign-in .sign-in-component__header', $wrapper);

          $signInConfirmHeader.text($signInConfirmHeader.text().replace('first_name', firstName));
          $signInConfirmHeader.text($signInConfirmHeader.text().replace('full_name', firstName));
        }

        if ($('.sign-in-component__fpw-link').length > 0) {
          site.signin.forgotPassword({
            resetPassword: true,
            emailNode: $('input.return-user-email'),
            errorListNode: $('#lpw-text'),
            forgotPasswordLink: $('#forgot-password'),
            forgotPasswordNote: $('p#forgot_pw_note')
          });
        }

        // Bind events:

        $showPass.on('change', function (e) {
          var show = $(this).is(':checked');

          $visiblePass.add($pass).toggle();
          if (show) {
            $('.visible-pass', $wrapper).each(function () {
              $(this).val($(this).parent().prev().val()).trigger('blur');
            });
          } else {
            $pass.each(function () {
              $(this).val($(this).next().children().first().val()).trigger('blur');
            });
          }
        });

        $signInForm.add($registerForm).on('submit', function () {
          // Set the password field to what's in the visible password field if
          // show password is checked
          var showPass = $showPass.is(':checked');

          if (showPass) {
            $pass.each(function () {
              $(this).val($(this).next().children().first().val());
            });
          }

          // Set a cookie so we remember which form was submitted so we can
          // launch the relevant confirmation overlay on the next page load
          var cookieVal = $(this).hasClass('sign-in-component__form--sign-in') ? 'signin-confirm' : 'register-confirm';

          // var cookieVal = $(this).hasClass('sign-in-component__form--sign-in') ? 'signin-confirm' : 'signin';
          $.cookie('signInOverlayState', cookieVal, { path: '/' });
        });

        $('.signin-overlay__toggle-form a', $wrapper).on('click.signIn', function (event) {
          event.preventDefault();
		  $error_messages_list;
		  if ($error_messages_list) {
            $error_messages_list.hide();
          }
          $wrapper.toggleClass('sign-in');
        });

        $('.sign-in-component__close', $wrapper).on('click.signIn', function (event) {
          event.preventDefault();
          $.colorbox.close();
        });
      } // /End initForm()

      $triggerButton.on('click.signIn', function (event) {
        var forceReturn = $(this).hasClass('js-launch-account--return');
        var returnURL = forceReturn ? $(this).attr('data-return-url') : null;
        var hasIAM = Drupal.settings && Drupal.settings.iam_signin;

        event.preventDefault();

        // If already signed in, this button works as a link to the account
        // landing.
        if (signedIn) {
          window.location = '/account/index.tmpl';
        }
        // If the form is already on the page, focus on the first element in it
        else if ($('.sign-in-component', context).length) {
          $('.sign-in-component', context).find('.form-text:visible').first().focus();
        } else {
          if (hasIAM) {
            generic.jsonrpc.fetch({
              method: 'vulcan.getConfig',
              params: [{ section: 'iam' }],
              async: false,
              onBoth: function (jsonRpcResponse) {
                var iamData = jsonRpcResponse.getValue();

                if ((iamData.enabled)) {
                  window.location = iamData.redirect_url;
                } else {
                  window.location = '/account/signin.tmpl';
                }
              }
            });
          } else {
            window.location = '/account/signin.tmpl';
          }
          if (site.facebook) {
            site.facebook.init();
          }
        }
      });

      if (!isSignInPage) {
        if (signInOverlayState === 'register-confirm' && signedIn) {
          if (site.facebook) {
            site.facebook.init();
          }
        }
      }

      // Run initForm directly on the context. This will only really be useful
      // for the sign in page, where the form is already embedded.
      _initForm($('.sign-in-component', context));
    }
  };
})(jQuery);
